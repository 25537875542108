import React, { Suspense } from 'react';
import { Admin, Resource } from 'react-admin';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from './theme';
import AuthPage from './components/authPage';
import { ReferralCodeList, ReferralCodeCreate, ReferralCodeEdit } from './components/referralCodes';
import authProvider from './provider/authProvider';
import dataProvider from './provider/dataProvider';
import { UserList, UserCreate } from './components/users';
import ChangePassword from './components/changePassword';

const ViewChildDetails = React.lazy(() => import('./components/viewChild'));

const App = () => (
  <Router>
    <ThemeProvider theme={darkTheme}>
      <Admin dataProvider={dataProvider} authProvider={authProvider} theme={darkTheme} loginPage={AuthPage}>
        <Resource name="users" list={UserList} create={UserCreate} />
        <Resource name="referral-codes" list={ReferralCodeList} create={ReferralCodeCreate} edit={ReferralCodeEdit} />
        <Route path="/users/:id/change-password/*" component={ChangePassword} />
        <Route path="/users/child/:id" element={() => (
          <Suspense fallback={<div>Loading...</div>}>
            <ViewChildDetails />
          </Suspense>
        )} />
      </Admin>
    </ThemeProvider>
  </Router>

);

export default App;