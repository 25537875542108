import React, { useState } from 'react';
import LoginForm from './loginForm';
import RegistrationForm from './registrationForm';

const AuthPage = () => {
    const [isLoginView, setIsLoginView] = useState(true);

    return (
        <div>
            {isLoginView ? (
                <>
                    <LoginForm onLoginSuccess={() => console.log('Logged in')} />
                
                </>
            ) : (
                <>  
                    <RegistrationForm onRegisterSuccess={() => console.log('Registered')} />
                    <button onClick={() => setIsLoginView(true)}>Have an account? Login</button>
                </>
            )}
        </div>
    );
};

export default AuthPage;