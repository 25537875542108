import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Nav, Form, Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from './registrationForm'
import './css/styles.css';

function Login() {
  const [activeKey, setActiveKey] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateToken = useCallback(async (token) => {
    try {
      const response = await fetch('https://api-stg.pic-a-talk.com/admin-api/users', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        navigate('/dashboard'); // Redirect to dashboard if the token is still valid
      } else {
        localStorage.removeItem('token'); // Remove invalid token
        navigate('/login'); // Redirect to login page
      }
    } catch (error) {
      localStorage.removeItem('token'); // Remove invalid token if any error occurs
      navigate('/login'); // Redirect to login page
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      validateToken(token);
    }
  }, [validateToken]);

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    setError('');

    const credentials = btoa(`${email}:${password}`);
    const authHeader = `Basic ${credentials}`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeader
      },
      body: JSON.stringify({ email, password })
    };

    try {
      const response = await fetch('https://api-stg.pic-a-talk.com/admin-api/login/', requestOptions);
      if (!response.ok) throw new Error('Failed to login');
      const data = await response.json();
      localStorage.setItem('token', data.token);
      navigate('/dashboard'); // Navigate to the dashboard after login
    } catch (error) {
      setError('Login failed. Please check your credentials and try again.');
    }
  };

  // If you have a register form, here is where you would handle it.
  //   const handleSubmitRegister = (event) => {
  //     event.preventDefault();
  //     // Your registration logic here
  //   };

  return (
    <Container className="mt-5" style={{ paddingLeft: '25%', paddingRight: '25%', paddingTop: '5%', minHeight: '100vh' }}>
      <div className='text-center'><h1>Pic-a-talk CMS</h1></div>
      <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        <Nav variant="tabs" className="justify-content-center">
          <Nav.Item>
            <Nav.Link eventKey="login">Login</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="register">Register</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="login">
            <Form onSubmit={handleSubmitLogin}>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Remember Me"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100">Login</Button>
              {error && <div className="alert alert-danger" role="alert">
                {error}
              </div>}
            </Form>
          </Tab.Pane>
          <Tab.Pane eventKey="register">
            <RegistrationForm />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
}

export default Login;