const authProvider = {
  login: async ({ username, password }) => {
    const credentials = btoa(`${username}:${password}`);
    const authHeader = `Basic ${credentials}`;
    const request = new Request('https://api-stg.pic-a-talk.com/admin-api/login/', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader
      }),
      body: JSON.stringify({ username, password })
    });

    return fetch(request)
      .then(response => {
        if (!response.ok) throw new Error('Login failed');
        return response.json();
      })
      .then(({ token }) => {
        if (token) {
          localStorage.setItem('token', token);
          return Promise.resolve();
        } else {
          throw new Error('No token returned');
        }
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  checkError: error => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem('token');
      return Promise.reject(new Error('Authorization failed'));
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;