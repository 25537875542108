const apiUrl = 'https://api-stg.pic-a-talk.com/admin-api';
const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }
    options.headers.set('Accept', 'application/json');
    
    const token = localStorage.getItem('token');
    if (token) {
        options.headers.set('Authorization', `Token ${token}`);
    }

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return await response.json();
};

const dataProvider = {
    getList: async (resource, params) => {
        const url = `${apiUrl}/${resource}/`;
        const { count, results } = await httpClient(url);
        return {
            data: results.map(record => ({
                ...record,
                id: record.id
            })),
            total: count
        };
    },
    getOne: async (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        const data = await httpClient(url);
        return {
            data: { ...data, id: data.id }
        };
    },
    getMany: async (resource, params) => {
        const promises = params.ids.map(id => {
            const url = `${apiUrl}/${resource}/${id}/`;
            return httpClient(url);
        });
        const data_1 = await Promise.all(promises);
        return {
            data: data_1.map(data => ({ ...data, id: data.id }))
        };
    },
    update: async (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}/`;
        const data = await httpClient(url, {
            method: 'PATCH',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(params.data)
        });
        return {
            data: { ...data, id: data.id }
        };
    },
    create: async (resource, params) => {
        const url = `${apiUrl}/${resource}/`;
        const data = await httpClient(url, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(params.data)
        });
        return {
            data: { ...data, id: data.id }
        };
    },
    delete: async (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}/`;
        await httpClient(url, {
            method: 'DELETE'
        });
        return {
            data: { id: params.id }
        };
    },
    deleteMany: async (resource, params) => {
        const promises = params.ids.map(id => {
            const url = `${apiUrl}/${resource}/${id}/`;
            return httpClient(url, { method: 'DELETE' });
        });
        const data = await Promise.all(promises);
        return {
            data: data.map(item => ({ id: item.id }))
        };
    }
};

export default dataProvider;
