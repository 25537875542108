import React, { useState } from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material';

const ChangePassword = () => {
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [password, setPassword] = useState('');

  const handleSave = async () => {
    try {
      await dataProvider.update('users', { id, data: { password } });
      notify('Password updated successfully', 'info');
    } catch (error) {
      notify(`Error: ${error.message || 'password not updated'}`, 'warning');
    }
  };

  return (
    <div>
      <h1>Change Password</h1>
      <TextField
        autoFocus
        margin="dense"
        id="password"
        label="New Password"
        type="password"
        fullWidth
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button onClick={handleSave} color="primary" disabled={!password}>
        Save
      </Button>
    </div>
  );
};

export default ChangePassword;