import { List, Datagrid, TextField, EmailField, SelectInput, SimpleForm, TextInput, Create, BooleanField, BooleanInput, FunctionField } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export const UserList = props => {
    const navigate = useNavigate();  // Initialize the useNavigate hook

    const handleNavigateToChangePassword = (id) => {
        console.log(`Navigating to change password for ID: ${id}`);
        navigate(`/users/${id}/change-password`);
    };

    const handleNavigateToViewChild = (id) => {
        navigate(`/users/child/${id}`);
    };


    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="first_name" label="First Name" />
                <TextField source="last_name" label="Last Name" />
                <EmailField source="email" />
                <BooleanField source="verified" label="Verified" />
                <FunctionField render={record => (
                    <>
                        <Button
                            color="primary"
                            onClick={() => handleNavigateToChangePassword(record.id)}
                        >
                            Change Password
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => handleNavigateToViewChild(record.id)}
                        >
                            View Child
                        </Button>
                    </>
                )} />
            </Datagrid>
        </List>
    );
};

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="email" />
            <SelectInput source="gender" choices={[
                { id: 'M', name: 'Male' },
                { id: 'F', name: 'Female' }
            ]} />
            <TextInput source="phone_number" />
            <TextInput source="password" type="password" />
            <BooleanInput source="verified" />
            <BooleanInput source="create_defaults" label="Create with Default Settings" defaultValue={true} />
            <BooleanInput source="create_blank_child" label="Allow Creation of Blank Child Records" defaultValue={false} />
        </SimpleForm>
    </Create>
);