import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // This sets the dark mode
    primary: {
      main: '#90caf9', // Example primary color
    },
    secondary: {
      main: '#f48fb1', // Example secondary color
    },
  },
  // Add other theme customizations here
});

export default darkTheme;