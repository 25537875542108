import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, Create, SimpleForm, TextInput, Edit } from 'react-admin';

// List Component for Referral Codes
export const ReferralCodeList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="code" />
            <TextField source="description" />
            <TextField source='uses' />
            <TextField source='max_uses' />
            <EditButton basePath="/referral-codes" />
            <DeleteButton basePath="/referral-codes" />
        </Datagrid>
    </List>
);

// Create Component for Referral Codes
export const ReferralCodeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" required />
            <TextInput source="description" required multiline />
        </SimpleForm>
    </Create>
);

// Edit Component for Referral Codes
export const ReferralCodeEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="code" required />
            <TextInput source="description" required multiline />
        </SimpleForm>
    </Edit>
);