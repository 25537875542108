import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function RegistrationForm() {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regExp = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/; // Regex to check for at least one uppercase letter, one number, and at least 8 characters
    return regExp.test(password);
  };
  const validateEmail = (email) => {
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
    return regExp.test(email);
  };

  const handleSubmitRegistration = async (event) => {
    event.preventDefault();
    setError('');
    setPasswordError('');
    setEmailError('');

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long, include at least one uppercase letter, and one number.');
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        'first_name': firstName,
        'middle_name': middleName, 
        'last_name': lastName,
        'email': email,
        'password': password,
        'phone_number': phoneNumber,
      })
    };

    try {
      const response = await fetch('https://api-stg.pic-a-talk.com/admin-api/register/', requestOptions);
      console.log(response);
      if (!response.ok) throw new Error('Registration failed');
      const data = await response.json();
      console.log('Registration successful', data);
      navigate('/login');
    } catch (error) {
      setError('Registration failed. Please try again.');
      console.error('Registration failed:', error);
    }
  };

  return (
    <Form onSubmit={handleSubmitRegistration}>
      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter first name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Middle Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter middle name"
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          isInvalid={!!emailError}
        />
        <Form.Control.Feedback type="invalid">
          {emailError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Create a password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          isInvalid={!!passwordError}
        />
        <Form.Control.Feedback type="invalid">
          {passwordError}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">Register</Button>
      {error && <div className="alert alert-danger mt-3" role="alert">
        {error}
      </div>}
    </Form>
  );
}

export default RegistrationForm;